import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClientRefPipe } from './client-ref.pipe';
import { ProjectRefPipe } from './project-ref.pipe';
import { UserRefPipe } from './user-ref.pipe';

@NgModule({
  declarations: [ProjectRefPipe, ClientRefPipe, UserRefPipe],
  imports: [CommonModule],
  exports: [ProjectRefPipe, ClientRefPipe, UserRefPipe],
})
export class EntityRefPipesModule {}
